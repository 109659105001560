@import './common_css/loadingOverlay.css';
@import './dashboardLocalization.css';

.plugin_container {
    padding-left: 15px;
    padding-right: 15px;
    height: 95vh;
}

.margin_top_row {
    margin-top: 1rem;
}

.tabs_style {
    padding-top: 1rem;
}